// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:2220aa09-bb61-4b71-a5b8-863fca0bdf13",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_uxM3KvnFB",
    "aws_user_pools_web_client_id": "4re9nnaofkbn2vgo60bsjindr6",
    "aws_cloud_logic_custom": [
        {
            "name": "GETComponentList",
            "endpoint": "https://o4b794byh5.execute-api.ap-southeast-2.amazonaws.com/Prod",
            "region": "ap-southeast-2"
        },
        {
            "name": "GETVideoURL",
            "endpoint": "https://dx1t3nskmk.execute-api.ap-southeast-2.amazonaws.com/Prod",
            "region": "ap-southeast-2"
        }
    ]
};


export default awsmobile;
