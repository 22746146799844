import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-route-guard';


const routes: Routes = [
  { path: '', loadChildren: './tabs/tabs.module#TabsPageModule' },
  // { path: 'video-live-recording', loadChildren: './pages/video-live-recording/video-live-recording.module#VideoLiveRecordingPageModule' },
  // { path: 'vod-nvr-recording', loadChildren: './pages/vod-nvr-recording/vod-nvr-recording.module#VodNvrRecordingPageModule' },
  // { path: 'switchlist', loadChildren: './pages/switchlist/switchlist.module#SwitchlistPageModule' },
  // { path: 'switch-detail', loadChildren: './pages/switch-detail/switch-detail.module#SwitchDetailPageModule' },
  // { path: 'vod-feed-detected-image', loadChildren: './pages/vod-feed-detected-image/vod-feed-detected-image.module#VodFeedDetectedImagePageModule' },
  // { path: 'device-settings', loadChildren: './pages/device-settings/device-settings.module#DeviceSettingsPageModule' },
  // { path: 'device-list', loadChildren: './pages/device-list/device-list.module#DeviceListPageModule' },
  // // { path: 'user-settings', loadChildren: './pages/user-settings/user-settings.module#UserSettingsPageModule' },
  // { path: 'camera-detail', loadChildren: './pages/camera-detail/camera-detail.module#CameraDetailPageModule' },
  // { path: 'sensor-detail', loadChildren: './pages/sensor-detail/sensor-detail.module#SensorDetailPageModule' },
  // { path: 'sensorlist', loadChildren: './pages/sensorlist/sensorlist.module#SensorlistPageModule' },
  // { path: 'vod-feed-event', loadChildren: './vod-feed-event/vod-feed-event.module#VodFeedEventPageModule' },
  // { path: 'vod-feed-event', loadChildren: './pages/vod-feed-event/vod-feed-event.module#VodFeedEventPageModule' },
  // // { path: 'tab4', loadChildren: './tab4/tab4.module#Tab4PageModule' },
  // { path: 'videofeed', loadChildren: './pages/videofeed/videofeed.module#VideofeedPageModule' },
  // { path: 'vod-date-time-picker', loadChildren: './pages/vod-date-time-picker/vod-date-time-picker.module#VodDateTimePickerPageModule'},
  // { path: 'vod-feed', loadChildren: './pages/vod-feed/vod-feed.module#VodFeedPageModule' }
];
@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
